const getChunkImport = (locale) => {
    return import(
      './i18n/data/fr.json'
    );

};

const loadMessages = async (locale) => {
  try {
    const chunkImport = await getChunkImport(locale);
    // Prefer loading `default` (for ESM bundles) and
    // fall back to normal import (for CJS bundles).
    return chunkImport.default || chunkImport;
  } catch (error) {
    console.warn(
      `Something went wrong while loading the app messages for ${locale}`,
      error
    );
    return {};
  }
};

export default loadMessages;
